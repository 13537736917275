import { Component, OnInit, AfterContentChecked  } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { RouterLinkActive } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ConfigService } from '../config.service';
import * as $ from "jquery";


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, AfterContentChecked {

  menu: any;
  isloggedIn: boolean;
  isLoggedIn: boolean;
  database = 'menu';
  menuOpen: boolean;
  profileImage: string;
  user: any;

  constructor(private location: Location,
    private auth: AuthenticationService,
    private config: ConfigService

  ) { }

  ngOnInit() {
    this.getMenu();
    this.menuOpen = false;
    this.isLoggedIn = this.auth.isloggedIn();
    this.getUser();

  
    
  }

  logout() {
    this.auth.logout();
  }

  getMenu() {
    this.config.getSettings(this.database).subscribe(
      settings => {
        this.menu = settings;
        console.log(settings);

      }
    );

  }

  toggleMenu(status:boolean) {
    this.menuOpen = status;

  }

  ngAfterContentChecked() {
    of(this.auth.isloggedIn()).subscribe(
      () => {
        this.getUser();
      }
    );

  }
  getUser() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    if (this.user) {
      this.profileImage = this.user.image;
    } else {
      this.profileImage = 'default-user.jpg';
    }

  }
}
